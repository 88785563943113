import type { Splide } from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const splides: Splide[] = [];

export default defineModule(
  async () => {
    const postsContentBlockElements = document.querySelectorAll<HTMLElement>(
      '.content-block.content-block--posts',
    );

    if (!postsContentBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    postsContentBlockElements.forEach((postsContentBlockElement) => {
      const splideElement =
        postsContentBlockElement.querySelector<HTMLElement>('.splide');
      if (!splideElement) return;

      splides.push(
        new Splide(splideElement, {
          autoWidth: true,
          arrows: true,
          omitEnd: true,
          focus: 0,
          gap: '2rem',
          breakpoints: {
            [BREAKPOINTS.lg]: {
              arrows: false,
            },
          },
        }).mount(),
      );
    });
  },
  () => {
    if (!splides.length) return;

    while (splides.length > 0) splides.pop()?.destroy(true);
  },
);
