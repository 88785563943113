import { defineModule } from "@/js/utils/helpers";

type ReturnObject = {
  id: string,
  field: string,
  value: number,
  nonce: string,
};

const disabledIDs:string[] = [];

const currentNonce = {
  value: "",
}

const getElements = () => ({
    listItems: document.querySelectorAll<HTMLElement>('.question'),
    feedbackButtonContainers: document.querySelectorAll<HTMLElement>('.faq_feedback_button_container'),
    initialNonce: document.querySelector<HTMLElement>('.faq_nonce'),
});

const confirmElements = (id:string) => {
  document.querySelector<HTMLElement>(`li[questionID='${id}'] .faq_feedback_sending`)?.classList.toggle('hidden');
  document.querySelector<HTMLElement>(`li[questionID='${id}'] .faq_feedback_thanks`)?.classList.toggle('hidden');
};

const toggleElements = (id:string) => {
  document.querySelectorAll<HTMLElement>(`li[questionID='${id}'] .faq_feedback_button`).forEach((element) => {
    element.toggleAttribute('disabled');
  });
  document.querySelector<HTMLElement>(`li[questionID='${id}'] .faq_feedback_sending`)?.classList.toggle('hidden');
  document.querySelector<HTMLElement>(`li[questionID='${id}'] .faq_feedback_request`)?.classList.toggle('hidden');  
};

const sendRequest = async (button:HTMLElement) => {
  const id = button.getAttribute('questionID');
  const feedback = button.getAttribute('feedback');

  if (id && !disabledIDs.includes(id)) {
    disabledIDs.push(id);
    toggleElements(id);
    try {
      const response = await fetch((window.location.origin + '/wp-json/feedback/v1/faq/'), {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          feedback: feedback,
          nonce: currentNonce.value,
        }),
      });

      const result:ReturnObject = await response.json();
      const feedbackNumberElement =  document.querySelector<HTMLElement>(`li[questionID='${id}'] .faq_feedback_number_${result.field}`);
      if(feedbackNumberElement) {
        feedbackNumberElement.innerText = result.value.toString();
      }
      confirmElements(id);
    } catch (error) {
      disabledIDs.splice(disabledIDs.indexOf(id), 1);
      toggleElements(id);
    };
  };
};

export default defineModule(
    () => {
      const { listItems, feedbackButtonContainers, initialNonce } = getElements();

      if (initialNonce) {
        currentNonce.value = initialNonce.innerText;
      };

      listItems.forEach(listItem => {
        const buttonContainers = listItem.querySelectorAll<HTMLElement>('.faq_feedback_button_container');
        const id = listItem.getAttribute('questionID');
        if (id) {
          buttonContainers.forEach(buttonContainer => {
            buttonContainer.setAttribute('questionID', id)
          });
        }
      });

      feedbackButtonContainers.forEach((feedbackButtonContainer) => {
        feedbackButtonContainer.addEventListener('click', () => {sendRequest(feedbackButtonContainer);});
      });
    },
    () => {
      const { feedbackButtonContainers } = getElements();
  
      feedbackButtonContainers.forEach((feedbackButtonContainer) => {
        feedbackButtonContainer.removeEventListener('click', () => {sendRequest(feedbackButtonContainer);});
      });
    },
);