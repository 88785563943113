// @ts-expect-error Pakcage doesn't have types
import Typewriter from 'typewriter-effect/dist/core';
import hljs from 'highlight.js/lib/core';
import js from 'highlight.js/lib/languages/javascript';
import cpp from 'highlight.js/lib/languages/cpp';
import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  codeContentBlockElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--code',
  ),
});

(
  [
    ['js', js],
    ['cpp', cpp],
  ] as const
).forEach(([name, lang]) => hljs.registerLanguage(name, lang));

const observer = new IntersectionObserver(async (entries) => {
  entries.forEach((entry) => {
    if (
      !entry.isIntersecting ||
      entry.target.querySelector<HTMLElement>('.Typewriter__wrapper')
    )
      return;

    const codeElement = entry.target.querySelector('[data-code]');
    if (!codeElement) return;

    const code = hljs.highlightAuto(codeElement.textContent!).value;

    new Typewriter(codeElement, {
      delay: 34,
    })
      .callFunction(() => {
        codeElement.classList.remove('invisible');
      })
      .typeString(code)
      .callFunction(
        ({
          elements: { cursor },
        }: {
          elements: {
            container: HTMLElement;
            cursor: HTMLElement;
            wrapper: HTMLElement;
          };
        }) => {
          cursor.remove();
        },
      )
      .start();
  });
});

export default defineModule(
  async () => {
    const { codeContentBlockElements } = getElements();
    if (!codeContentBlockElements.length) return;

    codeContentBlockElements.forEach((codeContentBlockElement) =>
      observer.observe(codeContentBlockElement),
    );
  },
  () => {
    const { codeContentBlockElements } = getElements();
    if (!codeContentBlockElements.length) return;

    codeContentBlockElements.forEach((codeContentBlockElement) =>
      observer.unobserve(codeContentBlockElement),
    );
  },
);
