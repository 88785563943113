import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { createElement, defineModule, nextTick } from '../../utils/helpers';

let hasDoneFirstMove = false;
let underlineElement: HTMLElement | null = null;

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarNavElement: document.querySelector<HTMLButtonElement>(
    '.navbar .navbar__nav',
  ),
});

const getActiveMenuItem = () => {
  const { navbarNavElement } = getElements();
  if (!navbarNavElement) return;

  const activeMenuItem = [...navbarNavElement.children]
    .filter(
      (child) =>
        child.classList.contains('current-menu-item') ||
        child.classList.contains('current-menu-parent'),
    )
    .at(0);

  if (!(activeMenuItem instanceof HTMLElement)) return null;

  return activeMenuItem && getComputedStyle(activeMenuItem).display !== 'none'
    ? activeMenuItem
    : null;
};

const moveToMenuItem = (menuItemElement: HTMLElement) => {
  if (!underlineElement) return;

  const rect = menuItemElement.getBoundingClientRect();
  const menuItemHasSubMenu = menuItemElement.classList.contains(
    'menu-item-has-children',
  );

  if (!getActiveMenuItem() && !hasDoneFirstMove) {
    underlineElement.classList.add('underline--no-transitions');
    nextTick(() =>
      underlineElement!.classList.remove('underline--no-transitions'),
    );
    hasDoneFirstMove = true;
  }

  underlineElement.classList.remove('underline--hidden');

  Object.assign(underlineElement.style, {
    width: `${rect.width}px`,
    transform: `translateX(${
      menuItemHasSubMenu
        ? menuItemElement.offsetLeft - 32.5
        : menuItemElement.offsetLeft
    }px)`,
  });
};

const moveToActiveMenuItem = (skipTransitions?: boolean) => {
  if (!underlineElement) return;
  const activeMenuItem = getActiveMenuItem();

  if (!activeMenuItem) {
    underlineElement.classList.add('underline--hidden');
    return;
  }

  if (skipTransitions) {
    underlineElement.classList.add('underline--no-transitions');
    nextTick(() =>
      underlineElement!.classList.remove('underline--no-transitions'),
    );
  }

  moveToMenuItem(activeMenuItem);
};

const breakpointMediaChecker = () => {
  if (!underlineElement) return;

  if (!getActiveMenuItem()) {
    underlineElement.classList.add('underline--hidden');
    return;
  }

  nextTick(() => moveToActiveMenuItem(true));
  underlineElement.classList.toggle(
    'underline--hidden',
    !BREAKPOINT_MEDIA_QUERIES.lg.matches,
  );
};

export default defineModule(
  () => {
    const { navbarElement, navbarNavElement } = getElements();
    if (!navbarElement || !navbarNavElement) return;

    underlineElement = createElement(
      'div',
      {
        className:
          'underline--hidden hidden lg:grid absolute place-items-center bottom-2 [&:not(.underline--no-transitions)]:transition-[transform,width,opacity,visibility] [&:not(.underline--no-transitions)]:duration-300 [&:not(.underline--no-transitions)]:ease-in-out [&.underline--hidden]:invisible [&.underline--hidden]:opacity-0 pointer-events-none',
      },
      [
        createElement('div', {
          className:
            'h-1 w-4 bg-black dark:bg-white rounded-full transition-colors duration-300 ease-in-out',
        }),
      ],
    );
    navbarNavElement.appendChild(underlineElement);

    moveToActiveMenuItem(true);

    nextTick(breakpointMediaChecker);

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      breakpointMediaChecker,
    );

    const menuItemElements = [...navbarNavElement.children];
    menuItemElements.forEach((menuItemElement) => {
      if (!(menuItemElement instanceof HTMLElement)) return;

      menuItemElement.addEventListener('mouseenter', () =>
        moveToMenuItem(menuItemElement),
      );

      menuItemElement.addEventListener('mouseleave', () =>
        moveToActiveMenuItem(),
      );
    });
  },
  () => {
    hasDoneFirstMove = false;

    const { navbarElement, navbarNavElement } = getElements();
    if (!navbarElement || !navbarNavElement || !underlineElement) return;

    underlineElement.remove();

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      breakpointMediaChecker,
    );

    const menuItemElements = [...navbarNavElement.children];
    menuItemElements.forEach((menuItemElement) => {
      if (!(menuItemElement instanceof HTMLElement)) return;

      menuItemElement.removeEventListener('mouseenter', () =>
        moveToMenuItem(menuItemElement),
      );

      menuItemElement.removeEventListener('mouseleave', () =>
        moveToActiveMenuItem(),
      );
    });
  },
);
