import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule, nextTick } from '../../utils/helpers';
import { Overlay, hasOpenOverlay, toggleOverlay } from '../../utils/overlays';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarTogglerElement: document.querySelector<HTMLButtonElement>(
    '.navbar .navbar__toggler',
  ),
});

const toggleNavbar = () => {
  const { navbarElement, navbarTogglerElement } = getElements();

  toggleOverlay(
    Overlay.MENU,
    navbarElement!.classList.toggle(
      'navbar--open',
      !hasOpenOverlay(Overlay.MENU),
    ),
  );

  navbarTogglerElement!.ariaExpanded = hasOpenOverlay(Overlay.MENU)
    ? 'true'
    : 'false';
};

const navbarBreakpointChecker = () => {
  const { navbarElement, navbarTogglerElement } = getElements();

  toggleOverlay(Overlay.MENU, false);

  navbarTogglerElement!.ariaExpanded = hasOpenOverlay(Overlay.MENU)
    ? 'true'
    : 'false';

  navbarElement!.classList.add('navbar--no-transitions');
  navbarElement!.classList.remove('navbar--open');
  nextTick(() => {
    navbarElement!.classList.remove('navbar--no-transitions');
  });
};

export default defineModule(
  () => {
    const { navbarElement, navbarTogglerElement } = getElements();
    if (!navbarElement || !navbarTogglerElement) return;

    navbarTogglerElement.addEventListener('click', toggleNavbar);

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
  () => {
    const { navbarElement, navbarTogglerElement } = getElements();
    if (!navbarElement || !navbarTogglerElement) return;

    navbarTogglerElement.removeEventListener('click', toggleNavbar);

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );

    toggleOverlay(Overlay.MENU, false);
  },
);
