import { toggleModal } from '.';
import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  faqQuestionButton: document.querySelector<HTMLInputElement>('button[data-faq-modal]'),
  faqModalError: document.querySelector<HTMLInputElement>('#modal-faq .frm_error_style'),
});

const onOtherQuestionSubmit = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  e.currentTarget.setAttribute('aria-expanded', 'true');

  toggleModal('faq', true);  
};

export default defineModule(
  () => {
    const { faqQuestionButton, faqModalError } = getElements();
    faqQuestionButton?.addEventListener('click', onOtherQuestionSubmit);
    faqModalError? toggleModal('faq', true) : false;
  },
  () => {
    const { faqQuestionButton } = getElements();
    faqQuestionButton?.removeEventListener('click', onOtherQuestionSubmit);
  },
);
