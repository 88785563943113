import { createFocusTrap, type FocusTrap } from 'focus-trap';
import { defineModule } from '../../utils/helpers';
import { Overlay, toggleOverlay } from '../../utils/overlays';

let focusTrap: FocusTrap | null = null;

export type ModalId = 'quotation' | 'faq' | 'pop-up';

const getElements = () => ({
  openModalElements:
    document.querySelectorAll<HTMLElement>('[data-open-modal]'),
  dismissModalElements: document.querySelectorAll<HTMLElement>(
    '[data-dismiss-modal]',
  ),
  toggleModalElements: document.querySelectorAll<HTMLElement>(
    '[data-toggle-modal]',
  ),
});

export const toggleModal = (id: ModalId, force?: boolean) => {
  const modalElement = document.querySelector<HTMLElement>(
    `[data-modal="${id}"]`,
  );
  if (!modalElement) return;

  const modalContentElement =
    modalElement.querySelector<HTMLElement>('.modal__content');
  if (!modalContentElement) return;

  force = force ?? !modalElement.classList.contains('modal--open');

  toggleOverlay(Overlay.MODAL, force);
  modalElement.classList.toggle('modal--open', force);

  if (force) {
    modalElement.setAttribute('aria-modal', 'true');
    modalElement.removeAttribute('aria-hidden');

    modalElement
      .querySelector<HTMLElement>('.frm_form_fields')
      ?.classList.remove('hidden');

    focusTrap = createFocusTrap(modalElement, {
      initialFocus: modalElement,
      allowOutsideClick: true,
      escapeDeactivates: false,
    });

    modalContentElement.addEventListener(
      'transitionend',
      () => focusTrap?.activate(),
      { once: true, passive: true },
    );
  } else {
    modalElement.removeAttribute('aria-modal');
    modalElement.setAttribute('aria-hidden', 'true');

    focusTrap?.deactivate();

    document
      .querySelectorAll<HTMLElement>('.modal.modal--form-completed')
      .forEach((_modalElement) => {
        const _modalContentElement =
          _modalElement.querySelector<HTMLElement>('.modal__content');
        if (!_modalContentElement) return;

        _modalContentElement.addEventListener(
          'transitionend',
          () => {
            _modalElement.classList.remove('modal--form-completed');
            _modalElement.querySelector<HTMLElement>('.frm_message')?.remove();
          },
          { once: true, passive: true },
        );
      });
  }
};

const handleOutsideModalContentClick = (e: Event) => {
  if (!(e.target instanceof HTMLElement)) return;

  const isOutsideClick = !e.target.closest<HTMLElement>('.modal__content');
  if (isOutsideClick) {
    const modalElement = e.target.closest<HTMLElement>('.modal');
    if (!modalElement) return;

    const modalId = modalElement.getAttribute('data-modal');
    if (!modalId) return;

    toggleModal(modalId as ModalId, false);
  }
};

const handleModalEvent = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const [shouldOpen, shouldClose] = [
    'data-open-modal',
    'data-dismiss-modal',
  ].map((attr) => (e.currentTarget as HTMLElement).getAttribute(attr));

  const targetModal = shouldOpen || shouldClose;
  if (!targetModal) return;

  toggleModal(
    targetModal as ModalId,
    // eslint-disable-next-line no-nested-ternary
    shouldOpen ? true : shouldClose ? false : undefined,
  );
};

export default defineModule(
  () => {
    const { openModalElements, dismissModalElements, toggleModalElements } =
      getElements();

    [
      ...openModalElements,
      ...dismissModalElements,
      ...toggleModalElements,
    ].forEach((openModalElement) =>
      openModalElement.addEventListener('click', handleModalEvent, {
        passive: true,
      }),
    );

    document.addEventListener('click', handleOutsideModalContentClick, {
      passive: true,
    });
  },
  () => {
    const { openModalElements, dismissModalElements, toggleModalElements } =
      getElements();

    [
      ...openModalElements,
      ...dismissModalElements,
      ...toggleModalElements,
    ].forEach((openModalElement) =>
      openModalElement.removeEventListener('click', handleModalEvent),
    );

    focusTrap?.deactivate();

    document.removeEventListener('click', handleOutsideModalContentClick);
  },
);
