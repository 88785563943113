import { defineModule } from '../utils/helpers';

const onFormComplete = (
  event: Record<string, any>,
  form: HTMLFormElement,
  response: { errors: string[]; content: string; pass: boolean },
) => {
  const formIdElement = form.querySelector<HTMLInputElement>(
    'input[name="form_id"]',
  );
  if (!formIdElement) return;

  const formId = formIdElement.value;
  const formMessageWrapperElement = document.getElementById(
    `frm_form_${formId}_container`,
  );
  if (!formMessageWrapperElement) return;

  const modalElement = formMessageWrapperElement.closest('.modal');
  if (modalElement) {
    modalElement.classList.add('modal--form-completed');
    modalElement
      .querySelector<HTMLElement>('.frm_form_fields')
      ?.classList.add('hidden');
  }
};

export default defineModule(
  () => {
    jQuery(document).on('frmFormComplete', onFormComplete);
  },
  () => {
    jQuery(document).off('frmFormComplete', onFormComplete);
  },
);
