/* eslint-disable no-restricted-syntax */
import { defineModule } from '@/js/utils/helpers';

const getElements = () =>
  document.querySelectorAll<HTMLElement>('.content-block.content-block--intro');

const observer = new IntersectionObserver(
  (entries) => {
    for (const entry of entries)
      if (entry.isIntersecting) {
        entry.target.classList.remove('invisible');
        observer.unobserve(entry.target);
      }
  },
  { threshold: 0.4 },
);

export default defineModule(
  () => {
    for (const introBlock of getElements()) observer.observe(introBlock);
  },
  () => {
    for (const introBlock of getElements()) observer.unobserve(introBlock);
  },
);
