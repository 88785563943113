import { toggleModal, type ModalId } from '.';
import { defineModule } from '../../utils/helpers';

export const HASH_TO_MODAL_ID_MAP: Record<string, ModalId> = {
  offerte: 'quotation',
};

const getModalIdFromHash = (hash: string): ModalId | undefined =>
  HASH_TO_MODAL_ID_MAP[hash.substring(1)];

const onElementClick = (e: Event) => {
  if (!(e.target instanceof HTMLAnchorElement)) return;

  const url = new URL(e.target.href);

  const modalId = getModalIdFromHash(url.hash);
  if (modalId) {
    e?.preventDefault();
    toggleModal(modalId, true);
  }
};

export default defineModule(
  () => {
    if (window.location.hash) {
      const modalId = getModalIdFromHash(window.location.hash);
      if (modalId) {
        toggleModal(modalId, true);
      }
    }

    document.addEventListener('click', onElementClick);
  },
  () => {
    document.removeEventListener('click', onElementClick);
  },
);
