import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarNavElement: document.querySelector<HTMLButtonElement>(
    '.navbar .navbar__nav',
  ),
});

const toggleSubmenu = (e?: Event) => {
  if (!e || BREAKPOINT_MEDIA_QUERIES.lg.matches) return;
  e.stopImmediatePropagation();
  e.preventDefault();

  const dropdownItemParentElement = (e.currentTarget as HTMLElement).closest(
    '.menu-item',
  );
  if (!dropdownItemParentElement) return;

  dropdownItemParentElement.classList.toggle('dropdown--open');
};

export default defineModule(
  () => {
    const { navbarElement, navbarNavElement } = getElements();
    if (!navbarElement || !navbarNavElement) return;

    const dropdownItemParentElements = [...navbarNavElement.children].filter(
      (child) => child.classList.contains('menu-item-has-children'),
    );
    dropdownItemParentElements.forEach((dropdownItemParentElement) => {
      const dropdownIconElement =
        dropdownItemParentElement.querySelector<HTMLElement>(
          '.menu-item__dropdown-icon',
        );
      if (!dropdownIconElement) return;

      dropdownIconElement.addEventListener('click', toggleSubmenu);
    });
  },
  () => {
    const { navbarElement, navbarNavElement } = getElements();
    if (!navbarElement || !navbarNavElement) return;

    const dropdownItemParentElements = [...navbarNavElement.children].filter(
      (child) => child.classList.contains('menu-item-has-children'),
    );
    dropdownItemParentElements.forEach((dropdownItemParentElement) => {
      const dropdownIconElement =
        dropdownItemParentElement.querySelector<HTMLElement>(
          '.menu-item__dropdown-icon',
        );
      if (!dropdownIconElement) return;

      dropdownIconElement.removeEventListener('click', toggleSubmenu);
    });
  },
);
