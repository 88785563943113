import { oneYearFromNow, defineModule } from '../utils/helpers';
// eslint-disable-next-line import/no-cycle
import { swup } from '../vendor/swup';

const getElements = () => ({
  themeTogglerElements: document.querySelectorAll<HTMLInputElement>(
    'input[type="checkbox"][name="theme-toggler"]',
  ),
});

const DARK = 'dark';
const LIGHT = 'light';

const prefersDarkThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');

const systemTheme = () => (prefersDarkThemeQuery.matches ? DARK : LIGHT);

const getTheme = () => document.cookie.match(/theme=(dark|light)/)?.[1];

const setTheme = (theme: string) => {
  const { themeTogglerElements } = getElements();

  themeTogglerElements.forEach((themeToggler) => {
    themeToggler.checked = theme === DARK;
  });

  document.cookie = `theme=${theme}; path=/; expires=${oneYearFromNow().toUTCString()}`;

  document.documentElement.classList.toggle('dark', theme === DARK);
  document.documentElement.classList.toggle('light', theme === LIGHT);
};

const toggleTheme = () => {
  setTheme(getTheme() === LIGHT ? DARK : LIGHT);

  /**
   * Empty swup cache to fix cached cookies
   */
  swup.cache.clear();
};

const onPrefersDarkThemeChange = () => setTheme(systemTheme());

export default defineModule(
  () => {
    const { themeTogglerElements } = getElements();

    if (!getTheme()) setTheme(systemTheme());

    prefersDarkThemeQuery.addEventListener('change', onPrefersDarkThemeChange);

    themeTogglerElements.forEach((themeToggler) => {
      themeToggler.addEventListener('change', toggleTheme);
    });
  },
  () => {
    const { themeTogglerElements } = getElements();

    prefersDarkThemeQuery.removeEventListener(
      'change',
      onPrefersDarkThemeChange,
    );

    themeTogglerElements.forEach((themeToggler) => {
      themeToggler.removeEventListener('change', toggleTheme);
    });
  },
);
