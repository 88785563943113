import type { Splide } from '@splidejs/splide';
import { BREAKPOINTS } from '@/js/utils/breakpoints';
import { defineModule } from '@/js/utils/helpers';

const splides: Splide[] = [];

export default defineModule(
  async () => {
    const pagebreakerContentBlockElements =
      document.querySelectorAll<HTMLElement>(
        '.content-block.content-block--pagebreaker',
      );
    if (!pagebreakerContentBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');
    const { AutoScroll } = await import(
      '@splidejs/splide-extension-auto-scroll'
    );

    pagebreakerContentBlockElements.forEach(
      (pagebreakerContentBlockElement) => {
        const splideElements =
          pagebreakerContentBlockElement.querySelectorAll<HTMLElement>(
            '.splide',
          );
        const isWide =
          pagebreakerContentBlockElement.classList.contains(
            'pagebreaker--wide',
          );
        splideElements.forEach((splideElement) => {
          splides.push(
            new Splide(splideElement, {
              type: 'loop',
              direction: splideElement.classList.contains('splide--reversed')
                ? 'rtl'
                : 'ltr',
              autoScroll: {
                speed: 0.75,
                pauseOnHover: false,
                pauseOnFocus: false,
              },
              gap: isWide ? '2.625rem' : '2rem',
              autoWidth: true,
              pagination: false,
              arrows: false,
              drag: false,
              breakpoints: {
                [BREAKPOINTS.lg]: {
                  gap: isWide ? '1.5rem' : '1rem',
                },
              },
            }).mount({ AutoScroll }),
          );
        });
      },
    );
  },
  () => {
    if (!splides.length) return;

    while (splides.length > 0) splides.pop()?.destroy(true);
  },
);
